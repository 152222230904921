@import '@ingka/variables/design-tokens';
@import '@ingka/variables/colours-css';

$header-height: 3.5rem;

// Source: https://github.com/ingka-group-digital/coworker-design-subsystem/blob/main/packages/styles/variables/_colours-css.scss
// NPM package does not seem to be publically available yet.
// https://youtrack.jetbrains.com/issue/WEB-41093/Mismatched-parameters-when-using-css-variables
//noinspection CssInvalidFunction
$colour-static-co-worker-blue-rgb: var(--colour-static-co-worker-blue, 0, 62, 114);
$colour-static-co-worker-blue: rgb(var(--colour-static-co-worker-blue, 0, 62, 114));
//noinspection CssInvalidFunction
$colour-static-co-worker-yellow-rgb: var(--colour-static-co-worker-yellow, 255, 219, 0);
$colour-static-co-worker-yellow: rgb(var(--colour-static-co-worker-yellow, 255, 219, 0));

$slm-red: #ff9090;
$slm-yellow: #ffff7c;
$slm-green: #a0ffa0;
$slm-grey: #bbb;

@mixin table-column-width($character-count) {
  width: $character-count * $font-size-75;
}

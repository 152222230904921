@import 'variables';

/*
Global styles that interact with existing Skapa rules.
*/

.input-field.slm-neutral {
  input[readonly] {
    background: $colour-neutral-2;

    & + span {
      border-color: $colour-neutral-2;
    }
  }
}

.prompt.slm-ok-cancel {
  /*
   * 20em seems to be about the minimum width for a Modal Prompt to display the OK / Cancel buttons with equal size.
   * Lower widths will cause the OK button to shrink disproportionately, which is due to the modal being too small.
   */
  min-width: 20rem;
}

.modal-wrapper.slm-confirm-submit p.slm-parameter {
  margin: 0;
}

.modal-wrapper.slm-confirm-submit p.slm-parameter:last-child {
  margin-bottom: $space-100;
}

/* Override of Skapa hide of the browser native date picker, until we find a better date picker */
.input-field input[type=date]::-webkit-inner-spin-button, .input-field input[type=date]::-webkit-calendar-picker-indicator, .input-field input[type=date]::-webkit-clear-button {
  display: unset !important;
  -webkit-appearance: unset !important;
}

.input-field input[type=date]:default {
  color: unset !important;
}

/*
Workarounds for issues in current versions of Skapa components.
These issues may be fixed in future versions.
*/

button {
  // Inherit base font
  font-family: inherit;
}

div.tabs__list button.tabs__tab {
  cursor: pointer;

  &.tabs__tab--active {
    cursor: default;
  }
}


/**
<Table> components must have `slm-table` class for increased CSS specificity.
 */
.slm-table.table {

  table {
    border-collapse: collapse;
    border: $thickness-thin solid $colour-elevation-1-border;
    border-radius: $radius-s;

    td, th {
      padding-bottom: $space-50;
      padding-top: $space-50;
      font-size: $font-size-75;
      padding-inline-start: $space-50;
      //padding-inline-end: $space-100;

      &.slm-number {
        text-align: right;
      }
    }

  }

  td.slm-red {
    background-color: $slm-red;
  }

  td.slm-yellow {
    background-color: $slm-yellow;
  }

  td.slm-green {
    background-color: $slm-green;
  }

  td.slm-grey {
    background-color: $slm-grey;
  }
}

div.slm-table:not(table) {
  overflow: inherit;
}

/**
Input components embedded in, or placed in close proximity to, a table .
 */
.select.slm-table-size, .input-field.slm-table-size {

  select, input {
    font-size: $font-size-75;
    height: $font-size-900; // 2.5rem
  }

  select {
    padding: $space-50;
  }

}

.tabs button .badge {
  margin-left: $space-50;
}

.badge.badge--orange {
  // Improves text readability
  color: white;
}

@import 'variables';

.slm-feedback.toast {

  // Needs to be in sync with the desktop @ingka/toast media width rule
  @media (min-width: 37.5em) {
    top: $header-height + $space-100;

    min-width: 22.5rem;
    width: auto; // Default: 22.5rem
    max-width: 30rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  p > span {
    margin-bottom: $space-50;

    display: flex;
    justify-content: flex-start;
    gap: $space-50;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.slm-feedback-details.modal-wrapper {
  h2 {
    margin: $space-50 0;
  }

  ul {
    margin: $space-50 0;
  }
}

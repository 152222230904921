@import 'variables';
@import 'skapa';
@import 'utility';

:root {
  /* The approximate top margin to use for individual vertical scrolling sections. E.g. tables with sticky headers. */
  --top-scroll-margin: 7.75rem;
}

body {
  padding: 0;
  margin: 0;
}

#root {
  min-width: 100%;
}

.divider {
  border-top: $thickness-thin solid var($colour-neutral-3);
}

.choice-item {
  background-color: $colour-neutral-1 !important;
}

.helpBtn {
  position: absolute !important;
  top: $space-75;
  right: $space-75;
  color: $colour-static-ikea-brand-blue;
}

.denseForm .input-field--labelled {
  margin-bottom: 14px;
}

.denseForm .input-field__wrapper input {
  height: 2.4rem;
}

.plate {
  position: relative;
  background-color: $colour-neutral-2;
  padding: $space-200;
  border: $thickness-thin solid $colour-interactive-subtle-border-default;
}

.staticGrid .plate {
  margin-bottom: $space-200;
}

.plate h1,
.plate h2,
.plate h3,
.plate h4 {
  margin-top: 0;
}

pre.json {
  font-size: $font-size-75;
}

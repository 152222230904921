@import "variables";

/*
Utility classes for specifying on-the-fly margins, paddings and alignment.

The letter suffixes use the scheme used by the Bootstrap utility classes.
https://getbootstrap.com/docs/5.2/utilities/spacing/
https://getbootstrap.com/docs/5.2/utilities/display/

The suffixes 's'  and 'e' signifies Start and End, meaning 'left' and 'right' in LTR.

The number suffix denotes the numbering used by Skapa design tokens. I.e. like space-100, space-75, etc.

To start accommodating responsive design, the rules also have RTL variants, but these have not been tested in practice.
*/

$spacings: (
        25: $space-25,
        50: $space-50,
        75: $space-75,
        100: $space-100,
        125: $space-125,
        150: $space-150,
        200: $space-200,
        250: $space-250,
        300: $space-300,
        400: $space-400,
        550: $space-550,
        750: $space-750,
        1000: $space-1000,
        1350: $space-1350,
);

@each $key, $value in $spacings {
  // Class and element used by Frame component.
  main.slm,
  // Class and element used Skapa Dialog
  div.prompt
  {
    .slm-ps-#{$key} {
      padding-left: $value;

      &:dir(rtl) {
        padding-right: $value;
      }
    }

    .slm-pe-#{$key} {
      padding-right: $value;

      &:dir(rtl) {
        padding-left: $value;
      }
    }

    .slm-mt-#{$key} {
      margin-top: $value;
    }

    .slm-mb-#{$key} {
      margin-bottom: $value;
    }

    .slm-ms-#{$key} {
      margin-left: $value;

      &:dir(rtl) {
        margin-right: $value;
      }
    }

    .slm-me-#{$key} {
      margin-right: $value;

      &:dir(rtl) {
        margin-left: $value;
      }
    }

  }
}

// Text align start
.slm-tas {
  text-align: left;

  &:dir(rtl) {
    text-align: right;
  }
}

// Text align end
.slm-tae {
  text-align: right;

  &:dir(rtl) {
    text-align: left;
  }
}

.slm-d-inline-block {
  display: inline-block;
}

.slm-d-flex-row-150 {
  display: flex;
  flex-direction: row;
  gap: $space-150;

  & > div {
    flex: 1 1 0;
  }
}

.slm-d-flex-row-ae-50 {
  display: flex;
  flex-direction: row;
  gap: $space-50;
  align-items: flex-end;
}

.slm-d-flex-row-ac-150 {
  display: flex;
  flex-direction: row;
  gap: $space-150;
  align-items: center;
}

ul.slm-plain {
  padding: 0;

  li {
    list-style: none;
    margin: 0 0 $space-100;
  }
}

fieldset.slm-pending-update {
  border: none;
  margin: 0;
  padding: 0;
}

@import 'variables';

.slm-cookie-consent-popup {
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 10000;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
  border-radius: 2px;
  padding: 5px;
  background: $colour-static-white;

  .content {

    max-width: 25rem;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
